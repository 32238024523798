import React from 'react';
import footerImage from '../assets/footer.jpg';
const Footer = () => {
  return (
    <footer className="footer">
      <img src={footerImage} alt="" width="100%"/>
    </footer>
  );
};

export default Footer;
