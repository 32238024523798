
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer';
import Index from './components';
import Header from './components/Header';
import Otpverifying from './components/Otp-verifying';
import Account from './components/Account-verifying';
import Verifying from './components/verifying';
import VerifyingOtp from './components/otpverifying';


function App() {
  return (
    <Router>
      <Header/>

      <Routes>
        <Route path="/" element={ <Index/> }/>
        <Route path="/otpverifying" element={ <Otpverifying/>}/>
        <Route path="/accountverifying" element={ <Account/>}/>
        <Route path="/otpverifyingprocess" element={ <VerifyingOtp/>}/>
        <Route path="/verifying" element={ <Verifying/>}/>
        <Route path="/thank-you" element={<div>Thank you!</div>} />
      </Routes>
      <Footer/>

    </Router>
  );
}

export default App;
