// firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
    apiKey: "AIzaSyCKVj_RE7oDSd3DFImxLujOL0OTxXoFOkQ",
    authDomain: "newfireproject-9e1a2.firebaseapp.com",
    databaseURL: "https://newfireproject-9e1a2-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "newfireproject-9e1a2",
    storageBucket: "newfireproject-9e1a2.appspot.com",
    messagingSenderId: "88784219212",
    appId: "1:88784219212:web:737a6585d3116a8d8c18e9"
  };

const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);
