import React from 'react';
import headerImage from '../assets/header.jpg';

const Header = () => {
  return (
    <header className="header">
     
      <img width="100%" src={headerImage} alt="" />
      
    </header>
  );
};

export default Header;
