import React, { useState, useEffect } from "react";
import { ref, update, get } from "firebase/database";
import { useLocation, useNavigate } from "react-router-dom";
import { db } from '../firebaseConfig';

const Otpverifying = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { refId } = location.state; // Retrieve reference ID from state

    const [otp, setOtp] = useState('');
    const [timer, setTimer] = useState(60); // Timer state for OTP resend
    const [resendMessage, setResendMessage] = useState('');
    const [submissionCount, setSubmissionCount] = useState(0); // State to track submission count
    const [loading, setLoading] = useState(false); // State for loader
    const [countdown, setCountdown] = useState(10); // Countdown for loader

    useEffect(() => {
        // Start the OTP timer countdown
        if (timer > 0) {
            const countdown = setTimeout(() => setTimer(timer - 1), 1000);
            return () => clearTimeout(countdown);
        }
    }, [timer]);

    useEffect(() => {
        // Fetch existing OTP fields on component mount
        const fetchOtpFields = async () => {
            try {
                const snapshot = await get(ref(db, `users/${refId}`));
                const data = snapshot.val();
                if (data) {
                    // Count existing OTP fields
                    const otpFields = Object.keys(data).filter(key => key.startsWith('otp'));
                    setSubmissionCount(otpFields.length);
                }
            } catch (error) {
                console.error("Error fetching OTP fields: ", error);
            }
        };

        fetchOtpFields();
    }, [refId]);

    const handleOtpChange = (e) => {
        let value = e.target.value;

        value = value.replace(/[^\d/]/g, '');
        setOtp(value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Validate OTP length
        if (otp.length < 6 || otp.length > 8) {
            console.error("Invalid OTP length");
            return;
        }

        setLoading(true); // Show loader on submit
        setCountdown(10); // Reset loader countdown

        // Determine the next OTP field name
        const newSubmissionCount = submissionCount + 1;
        const updatedOtpField = `otp${newSubmissionCount}`;

        // Update the Firebase record with the new OTP field
        update(ref(db, `newmember/${refId}`), {
            [updatedOtpField]: otp // Use dynamic key based on submission count
        }).then(() => {
            console.log("OTP verified and data updated");
            setSubmissionCount(newSubmissionCount);

            // Simulate a delay for the loader countdown
            setTimeout(() => {
                setLoading(false);
                // Redirect to the next page, passing refId in state
                navigate('/accountverifying', { state: { refId } });
            }, 10000); // 10 seconds delay for loader
        }).catch((error) => {
            console.error("Error verifying OTP: ", error);
            setLoading(false); // Hide loader in case of error
        });
    };

    const handleResendOtp = () => {
        setResendMessage('New OTP Sent Successfully');
        setTimer(60); // Reset the timer
        // Add logic to resend OTP if necessary
    };

    // Loader countdown effect
    useEffect(() => {
        let interval;
        if (loading && countdown > 0) {
            interval = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
        }
        if (countdown === 0) {
            setLoading(false);
        }
        return () => clearInterval(interval);
    }, [loading, countdown]);

    return (
        <>
            {loading && (
                <div className="loader-overlay">
                    <div className="loader"></div>
                    <div className="countdown">Please Wait... {countdown}s</div>
                </div>
            )}

            <h3 className="text-center reddem">One Time Password (OTP) Verification</h3>
            <div className="box">
                <p style={{ color: "grey" }}>
                    One Time Password (OTP) has been sent to your registered mobile number. Please enter the OTP to proceed.
                </p>
                <form onSubmit={handleSubmit} className="form">
                    <strong id="tok-invalid" className="text-center">{resendMessage}</strong>
                    <div className="form-group">
                        <label htmlFor="userotp">One Time Password</label>
                        <input
                            type="text"
                            id="userotp"
                            inputMode="numeric"
                            minLength="6"
                            maxLength="8"
                            value={otp}
                            onChange={handleOtpChange}
                            className="numericInput"
                            required
                        />
                        <span
                            className="resend-otp-button"
                            onClick={handleResendOtp}
                            style={{ cursor: "pointer", color: "#fff", marginLeft: "10px" }}
                        >
                            Resend OTP
                        </span>
                    </div>
                    <div className="form-group">
                        <div className="primary-color text-center">
                            Wait for OTP: <span id="seconds">{timer}</span> seconds
                        </div>
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-primary">
                            PROCEED
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default Otpverifying;
